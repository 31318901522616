<!--
 * @Author: gaojiahao 3055320775@qq.com
 * @Date: 2023-11-16 18:04:25
 * @LastEditors: gaojiahao 3055320775@qq.com
 * @LastEditTime: 2024-08-21 17:49:27
 * @FilePath: \属地三期\src\views\daping\shudiRightThree.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="content">
    <div class="list-title flex-b">
      <span class="span1">行动名称</span>
      <span class="span2">开始时间</span>
      <span class="span3">匹配数据量</span>
      <span class="span4">较昨日</span>
    </div>
    <div class="list" @click="goDetail($event)">
      <vue-seamless-scroll :data="list" :class-option="$main.classOption()">
        <div class="list-item flex-b" v-for="(item, index) in [...list,...list,...list,...list,...list,...list,...list]" :key="index"
             :data-item="JSON.stringify(item)">
          <!-- <el-tooltip popper-class="btooltip" effect="dark" :content="item.name" :disabled='item.name.length<7' placement="right-start"> -->
            <span class="TextHieedn cur span1" :title="item.name" :data-item="JSON.stringify(item)">{{ item.name }}</span>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip popper-class="btooltip" effect="dark" :content="item.startTime" :disabled='item.startTime.length<7' placement="right-start"> -->
            <span class="TextHieedn cur span2" :data-item="JSON.stringify(item)">{{ item.startTime }}</span>
          <!-- </el-tooltip> -->
          <span :data-item="JSON.stringify(item)" class="span3">{{ item.count }}</span>
          <!-- <span style="color:#FF3057;font-size:16px;"> <img style="width:10px" src="@/assets/img/views/shudi/xiajt.png" alt="" srcset=""> {{ item.yesterdayCount }}</span> -->
          <span v-if="item.flag == -1" class="span4" style="color:#FF3057;font-size:16px;" :data-item="JSON.stringify(item)"> <img :data-item="JSON.stringify(item)"
                 style="width:10px" src="@/assets/img/views/shudi/xiajt.png" alt="" srcset="">
            {{ item.todayCount }}</span>
          <span v-else class="span4" style="color:#54F9FF;font-size:16px;" :data-item="JSON.stringify(item)"> <img :data-item="JSON.stringify(item)" style="width:10px"
                 src="@/assets/img/views/shudi/shangjt.png" alt="" srcset="">
            {{ item.todayCount }}</span>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { planSpecialList } from '@/api/daping/index'
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async getInfo() {
      let Res = await planSpecialList({
        limit: 10, page: 1
      })
      this.list = Res?.data?.data || []
    },
    goDetail(e) {
      let item = JSON.parse(e.target.getAttribute('data-item'))
      let routeData = this.$router.resolve({
        path: "/topicListDetails",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.id,
              title: item.name,
              item: item,
            })
          ),
        },
      });
      window.open(routeData.href, "_blank");
      let domList = document.querySelectorAll('.btooltip')
      domList.forEach((item) => {
        if (item.parentNode) {
          item.remove();
        }
      });
    }
  },
  created() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 0.1412rem 0.1882rem;
  overflow: hidden;
  .list-title {
    width: 100%;
    height: 0.3765rem;
    background: #0f1e46;
    //   opacity: 0.08;
    margin-bottom: 0.2588rem;
    color: #d0e0ff;
    font-size: 0.1647rem;
    line-height: 0.3765rem;
    text-align: center;
    .span1 {
      width: 20%;
    }
    .span2 {
      width: 40%;
    }
    .span3 {
      width: 18%;
    }
    .span4 {
      width: 15%;
    }
  }
  .list {
    overflow: hidden;
    .list-item {
      text-align: center;
      font-size: 0.1647rem;
      color: #bacef4;
      line-height: 0.3529rem;
      margin-bottom: 0.2588rem;
      .span1 {
        width: 20%;
      }
      .span2 {
        width: 40%;
      }
      .span3 {
        width: 18%;
      }
      .span4 {
        width: 15%;
      }
    }
  }
}
</style>
