<template>
  <div class="content">
    <div class="list">
      <div class="list-title flex-b">
        <span class="span1">平台类型</span>
        <span class="span2">文章标题</span>
        <span class="span3">有害类型</span>
      </div>
      <div class="list-box" @click="goDetail($event)">
        <vue-seamless-scroll :data="list" :class-option="$main.classOption()">
          <div class="list-item flex-b cur" v-for="(item, index) in [...list,...list,...list,...list,...list,...list,...list]" :key="index" :data-id="item.id">
            <span class="TextHieedn span1" :data-id="item.id">{{ item.platformName||"无" }}</span>
            <!-- <el-tooltip popper-class="atooltip" effect="dark" :content="item.title" :disabled='item.title.length<20' placement="right-start"> -->
              <span class="TextHieedn span2" :data-id="item.id" :title="item.title">{{ item.title ||"无" }}</span>
            <!-- </el-tooltip> -->
            <!-- <el-tooltip popper-class="atooltip" effect="dark" :content="item.machineMessageTypesName" :disabled='item.machineMessageTypesName.length<6' placement="right-start"> -->
              <span class="TextHieedn span3" :data-id="item.id" :title="item.machineMessageTypesName">{{ item.machineMessageTypesName ||"无"}}</span>
            <!-- </el-tooltip> -->
              <!-- <span class="TextHieedn cur" @click="$main.lookYuanWen(item.sourceUrl)">{{ item.sourceUrl ||"无" }}</span> -->
          </div>
        </vue-seamless-scroll>
      </div>

    </div>
  </div>
</template>

<script>
import { getlist } from '@/api/harmful/index'
export default {
  data() {
    return {
      list: [],
      showTooltip: false,
      timer: null
    };
  },
  methods: {
    goDetail(e) {
      let id = e.target.getAttribute('data-id')
      this.$main.routeResolve('/harmfulDetail', { id: id })
      let domList = document.querySelectorAll('.atooltip')
      domList.forEach((item) => {
        if (item.parentNode) {
          item.remove();
        }
      });
    },
    // 有害信息
    async getInfo() {
      let Res = await getlist({
        page: 1,
        limit: 10,
      })
      this.list = Res?.data?.data?.list || []
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 0.1412rem 0.1882rem;
  overflow: hidden;
  .list {
    width: 95%;
    .list-title {
      width: 100%;
      height: 0.3765rem;
      background: #0f1e46;
      //   opacity: 0.08;
      margin-bottom: 0.2588rem;
      color: #d0e0ff;
      font-size: 0.1647rem;
      line-height: 0.3765rem;
      text-align: center;
      .span1 {
        width: 15%;
      }
      .span2 {
        width: 60%;
      }
      .span3 {
        width: 20%;
      }
    }
    .list-box {
      overflow: hidden;
      .list-item {
        text-align: center;
        font-size: 0.1647rem;
        color: #bacef4;
        line-height: 0.3529rem;
        margin-bottom: 0.2588rem;
        .span1 {
          width: 15%;
        }
        .span2 {
          width: 60%;
        }
        .span3 {
          width: 20%;
        }
      }
    }
  }
}
</style>
<style>
.atooltip {
  font-size: 0.1647rem !important;
  padding: 0.0941rem !important;
  line-height: 0.2353rem !important;
  border-radius: 0.0471rem !important;
  left: 2.9412rem !important;
}
.atooltip .popper__arrow {
  display: none;
}

.btooltip {
  font-size: 0.1647rem !important;
  padding: 0.0941rem !important;
  line-height: 0.2353rem !important;
  border-radius: 0.0471rem !important;
  left: 15.0588rem !important;
}
.btooltip .popper__arrow {
  display: none;
}
</style>